.m_footer {
    margin-top: auto;
    background: $c-dblue;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: $c-white;
    padding: 2%;

    .fot_txt {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            color: $c-white;
            text-transform: uppercase;
            font-size: 1.6vw;
            line-height: 1;
            font-weight: 300;

            img {
                height: 2vw;
                margin-right: 2%;
            }
        }

        p {
            font-weight: 300;
            font-size: 0.8vw;
            line-height: 1.3;
            margin: 2% 0 0;
        }
    }

    @include breakpoint($mnw768) {
        height: 5.5vw;
    }

    @include breakpoint($mxw768) {
        flex-direction: column;
        justify-content: center;
        padding: 8% 2%;

        .fot_txt {
            .email a img {
                height: 3vw;
            }

            a {
                font-size: 4vw;
                margin-bottom: 0px;
                line-height: 8vw;
                justify-content: center;

                img {
                    height: 4vw;
                    margin-right: 2%;
                }
            }

            p {
                font-size: 3vw;
                margin-top: 3%;
            }
        }
    }
}