.home_banner {
    height: 22.22vw;
    width: 100%;
    // position: relative;
    margin-bottom: 1.4vw;
    background: $c-dblue;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        margin-top: 2.78%;
        width: 100%;
        height: 2vw;
        background-image: linear-gradient(rgba(0, 16, 26, 0.5), rgba(0, 16, 26, 0));
    }

    .btm_bg {
        // content: '';
        position: absolute;
        top: 23vw;
        left: 0px;
        width: 100%;
        height: 2vw;
        background-image: linear-gradient(to top, rgba(0, 16, 26, 0.7), rgba(0, 16, 26, 0));
    }

    .bg_bx {
        width: 56vw;
        height: 22.22vw;
        overflow: hidden;

        img {
            object-fit: cover;
            object-position: 50% 50%;
            width: 56vw;
            height: 22.22vw;
            margin-top: -2%;
        }
    }

    @include breakpoint($mxw768) {
        margin-top: 8vw;
        margin-bottom: 0;
        height: 50vw;

        &::before{
            margin-top: 8vw;
        }
        .btm_bg {
            top: 56vw;
        }

        .bg_bx{
            display: none;
        }
    }
}

.logo_bg {
    overflow: hidden;
    position: absolute;
    width: 60vw;
    height: 60vw;
    margin-top: -10%;
    margin-left: -8.5%;
    top: 0px;
    left: 0px;

    .logo_bgIn {
        width: 60vw;
        height: 60vw;
        border-radius: 50%;
        border: 4px solid $c-green;
        overflow: hidden;

        .img_wrap {
            width: 60vw;
            height: 60vw;
            border-radius: 50%;
            border: 0.8vw solid transparent;
            overflow: hidden;
            margin: -4px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            opacity: 1;
            transition: opacity 500ms ease 0s;
        }
    }

    @include breakpoint($mxw768) {
        width: 100%;
        height: 50vw;
        margin-top: 0;
        margin-left: 0;
        position: static;

        .logo_bgIn {
            width: 100%;
            height: 50vw;
            border-radius: 0;
            border: 0;

            img{
                height: 50vw;
            }

            .img_wrap {
                width: 100%;
                height: 50vw;
                border-radius: 0;
                border: 0;
                margin: 0;
            }
        }
    }
}

.logo_bx {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: 5.5%;
    width: 34vw;
    height: 18vw;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));

    .main_logo {
        margin-top: 9%;
        margin-left: 10%;
        width: 24.8vw;
        height: 7.4vw;

        img {
            width: 100%;
            height: auto;
        }
    }

    .sub_logo {
        width: 40vw;
        height: 5vw;
        margin-top: 1.2%;
        background-image: linear-gradient(to right, rgb(0, 38, 61), rgb(0, 38, 61), rgba(0, 38, 61, 0));

        img {
            margin-top: 1%;
            margin-left: 14%;
            width: 19.8vw;
            height: 4.2vw;
        }
    }

    @include breakpoint($mxw768) {
        height: 34vw;
        width: 70%;
        background: rgba($c-white, 0.65);
        margin: 16% 0px 8% 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .main_logo {
            width: 43.6vw;
            height: 13.5vw;
            margin-top: 4%;
            margin-left: 0%;
        }

        .sub_logo {
            height: 10vw;
            width: 100%;
            background: rgb(0, 38, 64);
            margin: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 30.6vw;
                height: 6.8vw;
                margin: 0px;
            }
        }
    }
}

.nav_bx {
    position: absolute;
    @include center(x);

    ul {
        margin-left: 23vw;

        li {
            font-size: 1vw;
            font-weight: 300;
            line-height: 3.8vw;
            text-transform: uppercase;

            &:nth-child(3) {
                margin-left: 1.5vw;

                img {
                    height: 2.2vw;
                }
            }

            &:nth-child(4) {
                margin-left: 3vw;

                img {
                    height: 2.4vw;
                }
            }

            &:nth-child(5) {
                margin-left: 3.5vw;

                img {
                    height: 2.2vw;
                }
            }

            &:nth-child(6) {
                margin-left: 3.5vw;
            }

            a {
                color: $c-white;
                display: flex;
                align-items: center;
                white-space: nowrap;

                &:hover {
                    color: rgb(141, 158, 188);
                }
            }

            img {
                height: 2vw;
                margin-right: 1.4vw;
            }
        }
    }

    @include breakpoint($mxw768) {
        position: fixed;
        overflow: hidden auto;
        top: 0px;
        left: auto;
        right: -100%;
        min-height: 100vh;
        width: 100%;
        z-index: 1;
        background: $c-dblue;
        transform: none;
        transition: all 0.3s ease-in;

        &.active {
            right: 0;
        }

        .close_menu {
            height: 8vw;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 2vw;
            margin-bottom: 2vw;

            img {
                height: 2.5vw;
                display: block;
            }
        }

        ul {
            margin-left: 4%;

            li {
                font-size: 3vw;
                line-height: 10vw;
                margin-left: 0;
                font-weight: 400;

                &:nth-child(6),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    margin-left: 0;

                    img {
                        width: 4vw;
                        height: 4vw;
                        margin-right: 2.5vw;
                    }
                }

                img {
                    width: 4vw;
                    height: 4vw;
                    margin-right: 2.5vw;
                }
            }
        }
    }
}

.home_btmComp {
    position: relative;

    .btm_bg {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 27vw;
        overflow: hidden;
        opacity: 0.25;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: 50% 50%;
            opacity: 1;
            transition: opacity 500ms ease 0s;
            margin-top: -5%;
        }
    }
}
