/* global css */
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    display: inline-block;
}

a {
    text-decoration: none;
    //color: $c-red;
    transition: color 0.5s linear;
}

a:hover {
    text-decoration: none;
    color: $c-purple !important;
}

a:focus {
    text-decoration: none;
    // color: $c-black;
    outline: none;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

b,
strong {
    font-weight: 700;
}


/* Hides from IE-mac \*/

* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}


/* Common CSS \*/

.ohidden {
    overflow: hidden;
}

.FL {
    float: left;
}

.FR {
    float: right;
}

.dflex {
    display: flex;
}
.col{
    flex: 1 1 0%;
}

.CTR {
    text-align: center;
}

.TAR {
    text-align: right;
}

.uline {
    text-decoration: underline;
}

.UC {
    text-transform: uppercase;
}

.tCap {
    text-transform: capitalize !important;
}

:focus,
button:focus {
    outline: 0;
}

.PR {
    position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box; 
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}


html {
    text-size-adjust: 100%;
    height: 100%;
}

body{
    font-family: Lato, Helvetica, sans-serif;
    background-color: rgb(239 236 226);
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: 400;
    letter-spacing: inherit;
    line-height: inherit;
}

.strong {
    font-weight: 700;
}

.link{
    font-weight: 700;
    color: $c-blue;
    text-decoration: underline;

    &:hover{
        color: $c-blue1;
        text-decoration: underline;
    }
}

.md-none{
    display: none;

    @include breakpoint($mxw768) {
        display: block;
    }
}

.pad2v{
    padding: 2vw;
}

.mar5v{
    margin: 5% 0px;
    img{
        display: block;
        width: 100%;
        height: auto;
    }
}

.italic{
    font-style: italic;
}

.hd1{
    width: 100%;
    text-align: center;
}
.mb0{
    margin-bottom: 0 !important;
}

.sep_hr{
    border-bottom: 2px solid $c-dblue;
    width: 100%;
    margin: 0px 1rem 1rem;
}
figure{
    margin: 0;
}