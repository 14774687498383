//font-style
$font-lt: 300;


//grid
$mxw576: max-width 575.98px;
$mxw320: max-width 360px;
$mxw425: max-width 425px;
$mxw768: max-width 767px;
$mnw768: min-width 768px;
$mxw769: max-width 768px;
$mxw1000: max-width 1024px;
$mxw992: max-width 991px;
$mnw992: min-width 992px;
$mnw1025: min-width 1025px;
$mxw1280: max-width 1280px;
$mxw1200: max-width 1199px;
$mxw1440: max-width 1440px;
$mxw1366: max-width 1366px;
$mnw1440: min-width 1441px;
$mnw1920: min-width 1920px;
$mnw1600: min-width 1600px;
$mxw1920: max-width 1920px;
//colors
$c-black: #000;
$c-white: #FFF;
$c-blue: rgb(24, 90, 125);
$c-blue1:rgb(36, 136, 189);
$c-dblue:rgb(0, 38, 61);
$c-green: rgb(180, 189, 0);
$c-green1: rgb(120, 133, 60);
$c-purple: rgb(141, 158, 188);