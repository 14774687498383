header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $c-blue;
    height: 2.78vw;
    width: 100%;
    padding: 0 2%;
    position: relative;
    z-index: 1;

    .logo {
        flex: 1 1 0%;
        line-height: 2.78vw;
        font-family: Montserrat;
        font-weight: 200;
        font-size: 1.4vw;
        font-style: italic;
        letter-spacing: 0.1vw;

        a {
            color: $c-white;
        }
    }

    .mob_menu{
        display: none;
    }
    

    @include breakpoint($mxw768) {
        height: 8vw;
        position: fixed;
        top: 0px;
        z-index: 1;

        .logo,
        .header_rt {
            display: none;
        }
        .mob_menu{
            display: block;
        }
    }
}

.header_rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 0%;

    a {
        color: $c-white;
        text-transform: uppercase;
        height: 1.4vw;
        font-size: 1.4vw;
        line-height: 1;
        font-weight: 300;

        &:hover {
            color: rgb(141, 158, 188);
        }
    }

    .icn {
        margin-right: 1%;

        img {
            width: 1.8vw;
            display: block;
        }
    }

    .sep {
        padding-right: 12px;
        margin-left: 12px;
        color: $c-white;
        font-size: 1.4vw;
        font-weight: 300;
    }
}

.mob_menu {
    margin-left: auto;

    a {
        width: 10vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
            font-size: 2vw;
            line-height: 1;
            color: $c-white;
            text-transform: uppercase;
            padding-right: 1vw;
        }

        img {
            height: 3.4vw;
        }
    }
}